<template>
  <div class="date_picker_panel_components">
    <el-date-picker-panel
      @selectDate="handleDate"
      :startTime="startTime"
      :endTime="endTime"
      :defaultValue="defaultValue"
    ></el-date-picker-panel>
  </div>
</template>

<script>
export default {
  props: {
    start: {
      type: Number,
      default: 0,
    },
    end: {
      type: Number,
      default: 0,
    },
    default: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    handleDate(value) {
      let result = Object.assign({}, value);
      result.time = moment(result.value).unix();
      result.endTime = moment(result.valueEnd).unix();
      result.startTime = moment(result.valueStart).unix();
      this.$emit("dateSelect", result);
    },
  },
  computed: {
    startTime() {
      let startTime;
      if (this.start) {
        startTime = parseInt(
          new Date(new Date(this.start * 1000).toLocaleDateString()).getTime()
        );
      } else {
        startTime = 0;
      }
      return startTime;
    },
    endTime() {
      let endTime;
      if (this.end) {
        endTime = parseInt(
          new Date(new Date(this.end * 1000).toLocaleDateString()).getTime() +
            24 * 60 * 60 * 1000 -
            1
        );
      } else {
        endTime = 0;
      }
      return endTime;
    },
    defaultValue() {
      if (this.default) {
        return new Date(this.default * 1000);
      } else {
        return 0;
      }
    },
  },
};
</script>

<style scoped lang="less">
.date_picker_panel_components {
  /deep/.disabled div {
    background: #fff;
    span {
      background-color: #f5f7fa;
    }
  }
  /deep/td {
    box-sizing: border-box;
    max-width: 44px;
    max-height: 44px;
    overflow: hidden;
    padding: 0px;
    & > div {
      padding: 2px;
      width: 44px;
      height: 44px;
    }
  }
  /deep/ .el-date-table__row {
    height: 44px;
    overflow: hidden;
    box-sizing: border-box;
  }
  /deep/ .el-date-table td span {
    width: 40px;
    height: 40px;
    line-height: 40px;
    border-radius: 0px;
  }
}
</style>