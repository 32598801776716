<template>
  <div class="page_layout">
    <div class="box" v-loading="loading">
      <div class="title">
        <div @click="handBack"><i class="el-icon-back"></i></div>
        <h1 @click="handBack">填写入会信息</h1>
      </div>

      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="form_box"
      >
        <el-form-item prop="name_zh">
          <span slot="label" class="read_require">企业名称 <i></i></span>
          <el-input
            v-model="ruleForm.name_zh"
            class="long"
            placeholder="请输入您所属公司营业执照上的企业名称"
          ></el-input>
        </el-form-item>
        <div class="item_box">
          <el-form-item prop="country">
            <span slot="label" class="read_require">国家 <i></i></span>
            <CountrySelect
              v-model="ruleForm.country"
              size="medium"
              class="short"
              placeTitle="请输入国家"
            ></CountrySelect>
          </el-form-item>
          <el-form-item prop="city">
            <span slot="label" class="read_require">城市 <i></i></span>
            <CitySelect
              v-model="ruleForm.city"
              :countryId="ruleForm.country"
              size="medium"
              class="short"
              placeTitle="请输入城市"
            ></CitySelect>
          </el-form-item>
        </div>
        <div class="item_box">
          <el-form-item prop="registered_capital">
            <span slot="label" class="read_require">注册资金<i></i></span>
            <el-input
              v-model="ruleForm.registered_capital"
              class="short"
              type="number"
              placeholder="请输入注册资金 /万"
            ></el-input>
          </el-form-item>
          <el-form-item prop="found_year">
            <span slot="label" class="read_require">注册年份<i></i></span>
            <el-date-picker
              value-format='yyyy'
              v-model="ruleForm.found_year"
              :picker-options="p_op"
              type="year"
              placeholder="选择年"
              class="short"
            >
            </el-date-picker>
          </el-form-item>
        </div>
        <div class="item_box">
          <el-form-item prop="scale">
            <span slot="label" class="read_require">公司规模<i></i></span>
            <el-select v-model="ruleForm.scale" class="short">
              <el-option
                v-for="(item, i) in staffsizeList"
                :value="item.value"
                :label="item.label_zh"
                :key="i"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <span slot="label" class="read_require">企业网址</span>
            <el-input
              v-model="ruleForm.website"
              class="short"
              placeholder="请输入官网 （如果没有，可不填）"
            ></el-input>
          </el-form-item>
        </div>
        <div class="item_box">
          <el-form-item prop="contacts">
            <span slot="label" class="read_require">联系人<i></i></span>
            <el-input
              v-model="ruleForm.contacts"
              class="short"
              placeholder="请输入公司联系人"
            ></el-input>
          </el-form-item>
          <el-form-item prop="telephone">
            <span slot="label" class="read_require">联系电话<i></i></span>
            <el-input
              v-model="ruleForm.telephone"
              class="short"
              placeholder="请输入公司联系电话"
            ></el-input>
          </el-form-item>
        </div>
        <div class="item_box">
          <el-form-item prop="email">
            <span slot="label" class="read_require">邮箱<i></i></span>
            <el-input
              v-model="ruleForm.email"
              placeholder="请输入公司联系邮箱"
              class="short"
            ></el-input>
          </el-form-item>
          <el-form-item prop="fax">
            <span slot="label" class="read_require">传真</span>
            <el-input
              v-model="ruleForm.fax"
              class="short"
              placeholder="请输入公司传真（选填）"
            ></el-input>
          </el-form-item>
        </div>
        <el-form-item prop="address_zh">
          <span slot="label" class="read_require">企业地址 <i></i></span>
          <el-input
            v-model="ruleForm.address_zh"
            class="long"
            placeholder="请输入企业详细地址"
          ></el-input>
        </el-form-item>
        <el-form-item style="z-index: 200" prop='profile_zh'>
          <span slot="label" class="read_require">企业简介 <i></i></span>
          <el-input
            v-model="ruleForm.profile_zh"
            type="textarea"
            class="long"
            :autosize="{ minRows: 10, maxRows: 10 }"
           maxlength="600"
            style="height: 220px"
            show-word-limit
            placeholder="请输入公司简介"
          ></el-input>


        </el-form-item>
        <div class="fileUpload_box">
          <el-image
            class="img_show"
            v-if="ruleForm.url != ''"
            :src="ruleForm.url"
            :preview-src-list="[ruleForm.url]"
            fit="contain"
          ></el-image>

          <fileUpload
            style="float: right"
            @uploadCompleteOSS="_uploadCompleteOSS"
            :accept="fileUploadOptions.accept"
            :autoUpload="fileUploadOptions.autoUpload"
            
          >
            <el-button type="primary" icon="el-icon-upload" size="small"
              >上传营业执照</el-button
            >
          </fileUpload>
        </div>
        <div class="btn_box">
          <div class="btn_left" @click="handBack">取 消</div>
          <div class="btn_right" @click="submitForm('ruleForm')">确认提交</div>
        </div>
      </el-form>
    </div>
  </div>
</template>
<script>
import CountrySelect from "@/baseComponents/CountrySelect";
import CitySelect from "@/baseComponents/CitySelect";
import datePicker from "@/baseComponents/datePicker";
import fileUpload from "@/baseComponents/fileUpload";
import { staffsizeList } from "~scb/utils/enumValue";
import { validateEmail, validateTelephone } from "~/baseUtils/validate";
import {getCityNameById} from "@/baseUtils/AddressConfig/index"
export default {
  metaInfo: {
    title: "填写入会信息",
  },
  components: {
    CountrySelect,
    CitySelect,
    datePicker,
    fileUpload,
  },
  data() {
    var check_name_zh = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("企业名称不能为空"));
      } else {
        return callback();
      }
    };
    var check_country = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("请选择国家"));
      } else {
        return callback();
      }
    };
    var check_city = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("请选择城市"));
      } else {
        return callback();
      }
    };
    var check_registered_capital = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("请填写注册资金"));
      } else {
        return callback();
      }
    };
    var check_found_year = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("请选择企业注册年份"));
      } else {
        return callback();
      }
    };
    var check_scale = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("请选公司规模"));
      } else {
        return callback();
      }
    };
    var check_contacts = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("请填写联系人"));
      } else {
        return callback();
      }
    };
    var check_telephone = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("请填写联系人电话"));
      } else if (!validateTelephone(value)) {
        return callback(new Error("电话号格式错误"));
      } else {
        return callback();
      }
    };
    var check_email = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("请填写邮箱"));
      } else if (!validateEmail(value)) {
        return callback(new Error("邮箱格式错误"));
      } else {
        return callback();
      }
    };
    var check_address_zh = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("请填写企业地址"));
      } else {
        return callback();
      }
    };
    var check_profile_zh = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("请填企业简介"));
      } else {
        return callback();
      }
    };

    return {
      p_op: {
        disabledDate(time) {
          const curDate1 = new Date().getTime();
          return time.getTime() > curDate1;
        },
      },
      staffsizeList: staffsizeList,
      fileUploadOptions: {
        accept: ".jpg,.jpeg,.bmp,.png",
        autoUpload:true
      },
      loading: false,
      found_year:"",
      ruleForm: {
        company_id: "", //公司id
        name_zh: "", //公司中文名
        name_en: "",
        country: "", //国家id
        region_zh: "", //地区
        region_en: "",
        city: "",
        registered_capital: "", //注册资金
        found_year: "", //注册年份
        scale:"", //公司规模
        website: "", //公司官网,
        contacts: "", //联系人
        telephone: "",
        email: "",
        fax: "", //传真
        address_zh: "", //地址中文
        profile_zh: "", //企业简介
        logo:"",
        url: "",
        reason: "",
        parent_id: "",
        apply_from: "2",
        status: "",
        is_show: "",
        source:this.PJSource
      },
      rules: {
        name_zh: [{ validator: check_name_zh, trigger: "blur" }],
        country: [{ validator: check_country, trigger: "blur" }],
        city: [{ validator: check_city, trigger: "blur" }],
        registered_capital: [
          { validator: check_registered_capital, trigger: "blur" },
        ],
        found_year: [{ validator: check_found_year, trigger: "blur" }],
        scale: [{ validator: check_scale, trigger: "blur" }],
        contacts: [{ validator: check_contacts, trigger: "blur" }],
        telephone: [{ validator: check_telephone, trigger: "blur" }],
        email: [{ validator: check_email, trigger: "blur" }],
        address_zh: [{ validator: check_address_zh, trigger: "blur" }],
        profile_zh: [{ validator: check_profile_zh, trigger: "blur" }],
      },
    };
  },
  async created() {
    this.ruleForm.company_id=this.$store.state.baseStore.userInfo.company_id
    //获取公司信息 && 赋值
    let res = await this.$store.dispatch("API_company/getCompanyBrief", {
      company_id: this.ruleForm.company_id,
    });
    var msg = res.data;
    this.ruleForm.address_zh = msg.address_zh;
    this.ruleForm.contacts = msg.contacts;
    this.ruleForm.email = msg.email;
    this.ruleForm.fax = msg.fax;
    this.ruleForm.logo = msg.logo;
    
    try {
      this.ruleForm.found_year = msg.found_year.toString();
    } catch (error) {
      
    }
    
    this.ruleForm.registered_capital = msg.registered_capital;
    this.ruleForm.website = msg.website;
    this.ruleForm.telephone = msg.telephone;
    this.ruleForm.address_en = msg.address_en;
    this.ruleForm.city = msg.city;
    this.ruleForm.country = msg.country;
    this.ruleForm.is_show = msg.is_show;
    this.ruleForm.name_en = msg.name_en;
    this.ruleForm.name_zh = msg.name_zh;
    this.ruleForm.parent_id = msg.parent_id;
    this.ruleForm.reason = msg.reason;
    this.ruleForm.region_en = msg.region_en;
    this.ruleForm.region_zh = msg.region_zh;
    this.ruleForm.status = msg.status;
    this.ruleForm.source = msg.source;
    this.ruleForm.scale = msg.scale;
    this.ruleForm.profile_zh = msg.profile_zh;


    //获取企业证书 getQualification
    let Qualification_params = {
      company_id: this.ruleForm.company_id,
      certificate_type: 0,
      nopage: 1,
      usage: 3,
      website: 2,
    };
    let result_vip = await this.$store.dispatch(
      "API_index/getQualification",
      Qualification_params
    );
    try {
      this.ruleForm.url = result_vip.data[0].url;
    } catch (err) {}
  },
  mounted(){
    
  },
  methods: {
    getCityNameById,
    handBack() {
      window.history.go(-1);
    },
    _uploadCompleteOSS(res) {
      this.ruleForm.url = res.url;
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.upload_events();
        } else {
          return false;
        }
      });
    },
    async upload_events() {
      if (this.ruleForm.profile_zh == "") {
        this.$message.warning("请填写公司简介");
        return;
      }

      if (this.ruleForm.url == "" || !this.ruleForm.url) {
        this.$message.warning("请上传公司的营业执照");
        return;
      }
      this.loading = true;
      // 保存 提交公司营业执照
      let company_qualificationUpload = await this.$store.dispatch(
        "API_index/company_qualificationUpload",
        {
          company_id: this.ruleForm.company_id,
          url: this.ruleForm.url,
          certificate_type: 0,
        }
      );
      //  保存公司信息

      this.ruleForm.region_zh=this.getCityNameById(this.ruleForm.city,'zh')
      let result_save = await this.$store.dispatch(
        "API_index/saveCompanyMsg",
        this.ruleForm
      );

      //  注册
      this.ruleForm.source=this.PJSource
      let result_vip = await this.$store.dispatch(
        "API_index/companyVipApply",
        this.ruleForm
      );
      this.loading = false;
      if(result_vip.success){
      
        this.$message.success(this.$t('submitSuccess'))
        this.$router.push({ path: "/memberShip" });
      }else{
        this.$message.warning(result_vip.message)
      }
    },
  },
};
</script>
<style lang="less" scoped>
.long {
  width: 682px;
}
.short {
  width: 291px;
}

.read_require {
  padding-top: 9px;
  display: flex;
  padding-right: 10px;
  width: 95px;
  text-align: left;
  padding-left: 21px;
  height: 22px;
  font-size: 14px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.85);
  line-height: 22px;
  i {
    background: #ff4949;
    display: inline-block;
    width: 6px;
    height: 6px;
    border-radius: 3px;
    margin-top: 2px;
  }
}
.page_layout {
  .box {
    width: 1200px;
    box-sizing: border-box;
    margin: auto;
    .title {
      margin: auto;
      margin-bottom: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      div {
        width: 20px;
        height: 20px;
        text-align: center;
        line-height: 20px;
        border: 1px solid #7d8695;
        border-radius: 10px;
      }
      h1 {
        padding-left: 16px;
        width: 142px;
        height: 29px;
        font-size: 21px;
        font-weight: 400;
        color: #1f292e;
        line-height: 29px;
      }
    }
  }
  .form_box {
    width: 786px;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .item_box {
      display: flex;
    }
    .el-form-item {
      margin-bottom: 20px;
    }
    .fileUpload_box {
      position: relative;
      .img_show {
        width: 80px;
        height: 65px;
        position: absolute;
        top: -6px;
        left: 100px;
      }
    }
    .btn_box {
      display: flex;
      justify-content: center;
      margin-top: 40px;
      .btn_left {
        cursor: pointer;
        width: 336px;
        height: 42px;
        background: #f2f2f3;
        border-radius: 4px;
        font-size: 16px;
        font-weight: 800;
        color: #c0c4cc;
        line-height: 42px;
        text-align: center;
        margin-right: 30px;
      }
      .btn_right {
        cursor: pointer;
        width: 336px;
        height: 42px;
        background: linear-gradient(270deg, #0A4DAE 0%, #226AD3 100%);
        border-radius: 4px;
        font-size: 16px;
        font-weight: 800;
        color: #ffffff;
        line-height: 42px;
        text-align: center;
      }
    }
  }
}
</style>