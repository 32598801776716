import { render, staticRenderFns } from "./datePicker.vue?vue&type=template&id=453b3bbe&scoped=true"
import script from "./datePicker.vue?vue&type=script&lang=js"
export * from "./datePicker.vue?vue&type=script&lang=js"
import style0 from "./datePicker.vue?vue&type=style&index=0&id=453b3bbe&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../../../opt/deploy_node_modules/ailaworld/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "453b3bbe",
  null
  
)

export default component.exports